import classNames from 'classnames';
import React from 'react';
import * as styles from './file-input.module.scss';

type FileInputProps = {
    name: string,
    label: string,
    className?: string,
    required?: boolean,
    onChange: any,
    error?: string,
    value?: any
}

const FileInput: React.FC<FileInputProps> = ({
    name,
    label,
    className,
    required,
    onChange,
    error,
    value
}) => {
    return (
        <div className={classNames(className, styles.fileInput)}>
            <div className={styles.fileInputInner}>
                <label className={styles.fileInputInnerLabel} htmlFor={name}>{label}</label>
                <input
                    className={styles.fileInputInnerField}
                    onChange={onChange}
                    type="file"
                    id={name}
                    name={name}
                    required={required}
                    accept={'.pdf, .docx, .odt'}
                />
                <span className={styles.fileInputInnerItem}>{value.name}</span>
            </div>
            <span className={styles.fileInputError}>{error}</span>
        </div>
    );
};

export default FileInput;