import axios from 'axios';
import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm, useFormState } from 'react-hook-form';
import * as styles from '../../pages/apply-form/apply-form.module.scss';
import { getBase64 } from '../../utils/applyHandlers';
import { EMAIL_REGEX, INVALID_EMAIL, REQUIRED_MESSAGE, VALID_FILE_FORMATS } from '../../utils/regex';
import Button from '../button/Button';
import FileInput from '../file-input/FileInput';
import Input from '../input/Input';
import Textarea from '../textarea/Textarea';

type ApplyFormComponentProps = {
    setIsFetching: (value: boolean) => void
    setResult: (value: string) => void
}

const ApplyFormComponent: React.FC<ApplyFormComponentProps> = ({ setIsFetching, setResult }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [fileInput, setFileInput] = useState<HTMLInputElement | undefined>(undefined);
    const { handleSubmit, control, reset, setError, setValue } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            career: '',
            file: '',
            message: ''
        },
        mode: 'all',
    });
    const { errors } = useFormState({ control });

    useEffect(() => {
        if(localStorage.getItem('apply-for-name')) {
            setValue('career', localStorage.getItem('apply-for-name') as string)
        }
    }, []);
    useEffect(() => {
        const elementById = document.getElementById('cvFile') as HTMLInputElement;
        elementById && setFileInput(elementById)
    }, []);

    
    const onSubmit = async (values:  any) => {
        if (!executeRecaptcha) {
            return
        }
        setIsFetching(true);
        const result = await executeRecaptcha('homepage');
        const fileBase64 = await getBase64(values.file);
        try {
            const emailResult = await axios.post(
                `${process.env.GATSBY_APPLY}&filename=${values.file.name}`,
                {
                    ...values,
                    filename: values.file.name,
                    file: fileBase64,
                    careerLocation: localStorage.getItem('apply-for-location'),
                    captcha: result
                },
                {}
            );

            if(emailResult.data.code === 0) {
               reset();
               if (fileInput) {
                   fileInput.value = '';
               }
            }
            setIsFetching(false);
            setResult(emailResult.data.message);

        } catch (error: any) {
            console.debug(error.message);
            setIsFetching(false);
            setResult('Ooops! Something went wrong :( Please, try again later.');
        }
    }

    return (
        <form className={styles.applyForm} onSubmit={handleSubmit(onSubmit)}>
            <section className={classnames(classnames, styles.applyFormContainer, 'wrapper')}>
                <h1 className={styles.applyFormTitle}>APPLY NOW</h1>
                <div className={styles.applyFormContent}>
                    <div className={styles.applyFormContentFields}>
                        <Controller
                            name={'firstName'}
                            control={control}
                            rules={{
                                required: REQUIRED_MESSAGE,
                                maxLength: {
                                    value: 100,
                                    message: 'Entered value should have max 100 characters',
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    label={"First Name"}
                                    placeholder={'John'}
                                    onChange={field.onChange}
                                    value={field.value}
                                    error={errors["firstName"]?.message}
                                />
                            )}
                        />
                        <Controller
                            name={'lastName'}
                            control={control}
                            rules={{
                                required: REQUIRED_MESSAGE,
                                maxLength: {
                                    value: 100,
                                    message: 'Entered value should have max 100 characters',
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    label={"Last Name"}
                                    placeholder={'Dorian'}
                                    onChange={field.onChange}
                                    value={field.value}
                                    error={errors["lastName"]?.message}
                                />
                            )}
                        />
                        <Controller
                            name={'email'}
                            control={control}
                            rules={{
                                required: REQUIRED_MESSAGE,
                                pattern: { value: EMAIL_REGEX, message: INVALID_EMAIL }
                            }}
                            render={({ field }) => (
                                <Input
                                    label={"Email"}
                                    placeholder={'email@email.com'}
                                    onChange={field.onChange}
                                    value={field.value}
                                    error={errors["email"]?.message}
                                />
                            )}
                        />
                        <Controller
                            name={'career'}
                            control={control}
                            rules={{
                                required: REQUIRED_MESSAGE,
                            }}
                            render={({ field }) => (
                                <Input
                                    label={"Applying for the Post of"}
                                    placeholder={'Tech editor'}
                                    onChange={field.onChange}
                                    value={field.value || ''}
                                    error={errors["career"]?.message}
                                    readOnly
                                />
                            )}
                        />
                    </div>
                    <Controller
                        control={control}
                        name={"file"}
                        rules={{
                            required: REQUIRED_MESSAGE,
                        }}
                        render={({ field }) => (
                            <FileInput
                                name={'cvFile'}
                                label={'Upload CV'}
                                onChange={(e: any) => {
                                    const type = e.target.files[0].type;
                                    const fileSize = e.target.files[0].size / 1024 / 1024;
                                    if(!VALID_FILE_FORMATS.includes(type) || fileSize > 10) {
                                        setError('file', {type: 'pattern', message: 'Invalid file! Only 10 MB per one file and .pdf, .docx, .odt allowed'})
                                        setValue('file', '');
                                        if (fileInput) {
                                            fileInput.value = ''
                                        }
                                    } else {
                                        field.onChange(e.target.files[0]);
                                    }
                                }}
                                error={errors['file']?.message}
                                value={field.value}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={'message'}
                        rules={{
                            maxLength: {
                                value: 2000,
                                message: 'Entered value should have max 2000 characters',
                            },
                        }}
                        render={({ field }) => (
                            <Textarea
                                name={'message'}
                                label={'Message'}
                                placeholder={'Type Your Message Here!'}
                                value={field.value}
                                onChange={field.onChange}
                                error={errors["message"]?.message}
                            />
                        )}
                    />
                </div>
                <Button
                    name='APPLY NOW'
                    type='submit'
                    className={styles.applyFormButton}
                />
            </section>
        </form>
    )
}

export default ApplyFormComponent;