import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import AlertPopup from '../../components/alert-popup/AlertPopup';
import Spinner from '../../components/spinner/Spinner';
import ApplyFormComponent from '../../components/apply-form-component/ApplyFormComponent';

const ApplyFormPage: React.FC = () => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');

    useEffect(() => {
        return () => {
            localStorage.removeItem('apply-for-name')
            localStorage.removeItem('apply-for-location')
        }
    }, []);

    return (
        <>
            {isFetching && <Spinner />}
            <Layout>
                {result && <AlertPopup message={result} closePopup={() => setResult('')} />}
                <ApplyFormComponent setIsFetching={setIsFetching} setResult={setResult} />
            </Layout>
        </>
        
    );
};

export default ApplyFormPage;